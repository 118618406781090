<template>
  <div>
    <Header :title="title" />
    <b-container class="mt-2">
      <b-row>
        <b-col cols="12">
          <b-card class="card_setting" no-body>
            <b-form @submit.prevent="saveData" ref="form">
              <b-row>
                <b-col lg="6" md="6" sm="12" class="mb-3">
                  <div role="group">
                    <label class="label_input">Tipo de dashboard</label>
                    <v-select
                      class="style-chooser"
                      v-model="formData.selectedType"
                      label="text"
                      :options="typeDash"
                      :reduce="(option) => option.id"
                      placeholder="Seleccione area"
                    >
                      <div slot="no-options">Sin opciones</div>
                    </v-select>
                  </div>
                </b-col>
                <b-col lg="6" md="6" sm="12" class="mb-3">
                  <div role="group">
                    <label class="label_input">Sede</label>
                    <v-select
                      v-model="formData.selectedBrand"
                      class="style-chooser"
                      label="text"
                      :options="brands"
                      placeholder="Seleccione sede"
                      @input="loadServiceByBrand"
                    >
                      <div slot="no-options">Sin opciones</div>
                    </v-select>
                  </div>
                </b-col>
                <b-col lg="6" md="6" sm="12" class="mb-3">
                  <div role="group">
                    <label class="label_input">Servicio</label>
                    <v-select
                      class="style-chooser"
                      v-model="formData.selectedService"
                      label="text"
                      :options="services"
                      :reduce="(option) => option.id"
                      placeholder="Seleccione servicio"
                      @input="loadAreaByService"
                    >
                      <div slot="no-options">Sin opciones</div>
                    </v-select>
                  </div>
                </b-col>
                <b-col lg="6" md="6" sm="12" class="mb-3">
                  <div role="group">
                    <label class="label_input">Área a visualizar</label>
                    <v-select
                      class="style-chooser"
                      v-model="formData.selectedArea"
                      label="text"
                      :options="areas"
                      placeholder="Seleccione area"
                    >
                      <div slot="no-options">Sin opciones</div>
                    </v-select>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="d-flex justify-content-end mt-5">
                  <b-button
                    type="submit"
                    class="btn btn-success"
                    :disabled="isBusy || $v.$invalid"
                  >
                    <b-spinner v-if="isBusy" small />
                    Generar dashboard
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BContainer,
  BCard,
  BForm,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import Header from "@/components/header/Header";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex"

export default {
  inject: ["patientBedRepository"],
  components: {
    Header,
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      title: "Configuración de dashboard",
      isBusy: false,
      formData: {
        selectedBrand: null,
        selectedService: null,
        selectedArea: null,
        selectedType: null,
      },
      brands: [],
      services: [],
      areas: [],
      typeDash: [
        {
          id: 1,
          text: "Dashboard Sensores",
        },
        {
          id: 2,
          text: "Dashboard Llamados"
        },
      ]
    };
  },
  computed: {
    ...mapState("user", ["loggedUser"]),
  },
  validations: {
    formData: {
      selectedBrand: {
        required,
      },
      selectedService: {
        required,
      },
      selectedArea: {
        required,
      },
      selectedType: {
        required,
      },
    },
  },
  async mounted() {
    this.loadBrandByClient();
  },
  methods: {
    saveData() {
      const me = this;
      const brand = me.removeAccents(me.formData.selectedBrand.text.split(' ').join('_'));
      const area = me.removeAccents(me.formData.selectedArea.text.split(' ').join('_'));

      let name = null;

      if (me.formData.selectedType == 1) {
        me.$router.push({
          name: "device",
          params: {
            sede: brand,
            idBrand: me.formData.selectedBrand.id,
            area: area,
            id: me.formData.selectedArea.id,
          }
        });
      } else {
        me.$router.push({
          name: "patient",
          params: {
            sede: brand,
            area: area,
            id: me.formData.selectedArea.id,
          }
        });
      }
    },
    async loadBrandByClient() {
      const me = this;
      try {
        const res = await me.patientBedRepository.getAllBrandByClient(
          me.loggedUser.customer_id
        );
        res.forEach((element) => {
          me.brands.push({ id: element.id, text: element.name });
        });
      } catch (error) {
        // console.log(error);
      }
    },
    async loadServiceByBrand(item) {
      const me = this;
      me.resetSelect('1');
      try {
        const { data } = await me.patientBedRepository.getAllServicesByBrand(
          item.id
        );
        if (data.length > 0) {
          data.forEach((el) => {
            me.services.push({ id: el.id, text: el.name });
          });
        } else {
          me.$bvToast.toast(
            "No existen servicios registrado para la sede seleccionada",
            {
              title: "Sin servicios",
              toaster: "b-toaster-bottom-right",
              variant: "danger",
              solid: true,
              appendToast: true,
            }
          );
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async loadAreaByService(id) {
      const me = this;
      me.resetSelect('2');
      try {
        const { data } = await me.patientBedRepository.getAllAreaByService(id);

        data.forEach((el) => {
          me.areas.push({ id: el.id, text: el.name });
        });
      } catch (error) {
        me.$bvToast.toast(
          "No existen areas registrado para el servicio seleccionado",
          {
            title: "Sin areas",
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            solid: true,
            appendToast: true,
          }
        );
        // console.log(error);
      }
    },
    resetSelect(type) {
      const me = this;
      if (type == '1') {
        me.formData.selectedService = null;
        me.formData.selectedArea = null;
        me.services = [];
        me.areas = [];
      } else {
        me.formData.selectedArea = null;
        me.areas = [];
      } 
    },
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
  },
};
</script>

<style lang="css" scoped>
  .card_setting {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
    0 4px 15px 0 rgba(133, 132, 132, 0.15);
    border-radius: .5rem;
    border: none;
    padding: 20px;
  }
</style>
